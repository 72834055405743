module.exports = {
	//Get
	GetInfo(URL, callback) {
		if(URL.indexOf("?") != -1){
			URL+='&rand='+ Math.random()
		}else{
			URL+='?rand='+ Math.random()
		}
		// axios.get(URL,{ withCredentials: true },{
		axios.get(URL,{
			headers: {
			'content-type': 'application/x-www-form-urlencoded'
			}
		}).then(function (res) {
			callback(res);
		}).catch(function (error) {
			callback({"data":{"code":-1,"msg":error,"data":null}});
		});
	},
	//Post
	PostInfo(URL, PostData, callback) {
		// axios.post(URL,PostData,{ withCredentials: true },{
		axios.post(URL,PostData,{
			headers: {
			'content-type': 'application/x-www-form-urlencoded'
			}
		}).then(function (res) {
			callback(res);
		}).catch(function (error) {
			callback({"data":{"code":-1,"msg":error,"data":null}});
		});
	},
	setCookie(name,value){
	    var Days = 30;
	    var exp = new Date(),expe = new Date();
	    exp.setTime(exp.getTime() + Days*24*60*60*1000);
	    document.cookie = name + "="+ escape (value) + ";expires=" + (value==''?expe.toGMTString():exp.toGMTString());
	},
	getCookie(cookie_name) {
	    var allcookies = document.cookie
	    var cookie_pos = allcookies.indexOf(cookie_name)
	    if (cookie_pos != -1) {
	        cookie_pos = cookie_pos + cookie_name.length + 1
	        var cookie_end = allcookies.indexOf(";", cookie_pos)
	        if (cookie_end == -1) {
	            cookie_end = allcookies.length
	        }
	        var value = unescape(allcookies.substring(cookie_pos, cookie_end))
	    }
	    return value;
	},
	CheckLogin(that){
		// console.log(this.getCookie('loginkey'))
		if(this.getCookie('loginkey') == undefined){
			that.$router.push('login');
			return;
		}else{
			const userInfo = {
				name: 'anyuser',
				balance: '0',
				isLogin: true,
			};

			var arr = this.getCookie('loginkey').split("."),t_userInfo
			t_userInfo = JSON.parse(atob(arr[1]))
			this.setData('username',t_userInfo.username)
			
			userInfo.name = t_userInfo.username
			userInfo.balance = t_userInfo.point
			// console.log(atob(arr[1]))
			that.$store.dispatch("updateUserInfo", userInfo);
		}
	},
	setJson(key, value) {
        let jsonString = JSON.stringify(value);
        try {
            localStorage.setItem(key, jsonString);
        } catch (e) {
			console.log(e)
            // error
        }
    },
    setData(key, value) {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            // error
        }
    },
    getData(key) {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return value;
            }
        } catch (e) {
            // error
        }

    },
    getJson(key) {
        try {
            const value = localStorage.getItem(key);
            if (value) {
                return JSON.parse(value);
            }
        } catch (e) {
            // error
        }

    },
    clear() {
        localStorage.clear();
    },
};

