<template>
  <div class="page">

    <Nav activeIndex="1"></Nav>
    
    <el-row :gutter="24">
      <el-col :span="1" class="subtitle">
        <h2>电影</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col :xs="12" :sm="6" :md="4" :lg="3" :xl="3" style="padding: 10px;" v-for="(o, index) in 8" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <!-- <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image"> -->
          <el-image :src="imageUrl" fit="contain">
            <div slot="error" class="image-slot">
              <img src="@/assets/no_img.png">  
            </div>
            <div slot="placeholder" class="image-slot">
              <img src="@/assets/loading.gif">  
            </div>
          </el-image>
          <div style="padding: 10px;">
            <span class="one_line_hidden">好吃的汉堡</span>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="1" class="subtitle">
        <h2>电视剧剧剧</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="12" :sm="6" :md="4" :lg="3" :xl="3" style="padding: 10px;" v-for="(o, index) in 8" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <!-- <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image"> -->
          <el-image :src="imageUrl" fit="contain">
            <div slot="error" class="image-slot">
              <img src="@/assets/no_img.png">  
            </div>
            <div slot="placeholder" class="image-slot">
              <img src="@/assets/loading.gif">  
            </div>
          </el-image>
          <div style="padding: 10px;">
            <span class="one_line_hidden">好吃的汉堡</span>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="24">
      <el-col :span="1" class="subtitle">
        <h2>电视剧剧剧</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :xs="12" :sm="6" :md="4" :lg="3" :xl="3" style="padding: 10px;" v-for="(o, index) in 8" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <!-- <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image"> -->
          <el-image :src="imageUrl" fit="contain">
            <div slot="error" class="image-slot">
              <img src="@/assets/no_img.png">  
            </div>
            <div slot="placeholder" class="image-slot">
              <img src="@/assets/loading.gif">  
            </div>
          </el-image>
          <div style="padding: 10px;">
            <span class="one_line_hidden">好吃的汉堡</span>
          </div>
        </el-card>
      </el-col>
    </el-row>





    
    
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
export default {
  name: 'HomeView',
  components: {
    Nav
  },
  data() {
    return {
      imageUrl: 'https://yewootv.com/upload/vod/20240615-1/7d9ca3ad4a8c1aa669114ca223ecd17d.jpg'
    };
  },
  methods: {
    
  }
}
</script>
<style>
  .subtitle{
    text-align: left;
    min-width: 150px;
  }
</style>