<template>
  <div>
    
    <Login :menuShow="navShow" :navCollapse="true"></Login>

    <div id="app" class="page">
      
        <blockquote v-if="HomePage">
          <div class="logo">
            <router-link to="/">
              <img src="@/assets/logo.png" alt="뭐이야" style="width: 200px;">
            </router-link>
          </div>

          <transition name="el-zoom-in-top">
            <div v-show="navShow" ><Nav :collapse="false" :unline="false" :activeIndex="this.$link_index"></nav></div>
          </transition>
          
          <el-input placeholder="키워드 입력" @keyup.enter.native="search" v-model="keys" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
          </el-input>
        </blockquote>
        <blockquote v-else>
          <div class="logo">
            <el-row :gutter="24" type="flex" align="bottom" justify="center">
              <el-col :span="24">
                
                <transition name="el-zoom-in-top">
                  <div v-show="navShow" ><Nav :collapse="false" :unline="false" :activeIndex="this.$link_index"></nav></div>
                </transition>

              </el-col>
            </el-row>

            <el-row :gutter="24" type="flex" align="top" >
              <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6"  type="flex" align="top" justify="center">
                <div>
                  <router-link to="/">
                    <img src="@/assets/logo.png" alt="뭐이야" style="width: 100px;">
                  </router-link>
                </div>
              </el-col>
              <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18" justify="center">
                <el-input placeholder="키워드 입력" @keyup.enter.native="search" v-model="keys">
                  <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
                </el-input>
              </el-col>
            </el-row>
          </div>
        </blockquote>
        
        

        <!-- <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1">드라마</el-menu-item>
          <el-menu-item index="2">시사예능</el-menu-item>
          <el-menu-item index="3">애니메이션</el-menu-item>
          <el-menu-item index="4">영화</el-menu-item>
          <el-menu-item index="5">해외드라마</el-menu-item>
        </el-menu> -->

        <!-- <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link> -->
      
      
      <router-view v-loading="false"/>

      <el-divider content-position="right">moiya.cn</el-divider>

    </div>
  </div>
</template>
<script>
import PageSearch from './views/PageSearch.vue';
import Nav from '@/components/Nav.vue'
import Login from '@/components/Login.vue'

export default {
  components: {
    Nav,
    Login
  },
  data() {
    return {
      navShow:true,
      topHeight:'0',
      activeIndex: '1',
      keys:'',
      HomePage:false
    };
  },
  watch: {
    // 监听路由对象 $route 的变化
    '$route': {
      handler: function (to, from) {
        // 路由变化时执行的操作
        this.onRouteChange(to, from);
      },
      // 设置为深度监听
      deep: true
    }
  },
  created (){
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
    // 根据页面宽度做相应处理
    if (width <= 768) {
      // console.log('页面宽度小于768像素');
      this.navShow = false
    } else {
      // console.log('页面宽度大于等于768像素');
      this.navShow = true
    }
  },

  mounted () {
    
    var that = this

    window.addEventListener('resize', function() {
      // 获取窗口宽度
      var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    
      // 根据页面宽度做相应处理
      if (width <= 768) {
        // console.log('页面宽度小于768像素');
        that.navShow = false
      } else {
        // console.log('页面宽度大于等于768像素');
        that.navShow = true
      }
    });

    

    

  },
  methods: {
    onRouteChange(to, from) {
      // console.log(this.$route.fullPath)
      // console.log(window.location.href)
      if(window.location.href.endsWith('/')){
        this.topHeight = '10'
        this.HomePage = true
      }else{
        this.topHeight = '0'
        this.HomePage = false
      }

      
    },
    search() {
      if (this.keys!=='') {
        this.goTo('/PageSearch?keys='+this.keys+'&page=1')
      }
    },
    goTo(path) {
      // console.log(path)
      // console.log(this.$router.currentRoute.fullPath)
      if (path !== this.$router.currentRoute.fullPath) {
          this.$router.push(path);
      }
    }
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // }
  }
}
</script>
<style>
.page{
  max-width:1200px;
  margin: 0 auto;
}
body{
  /* background-color: black;
  filter: invert(1) hue-rotate(180deg) brightness(200%) saturate(300%); */
}
body img{
  /* filter:none; */
}
.eye-protection-mode {/* 护眼模式 */
  background-color: black;
  filter: invert(1) ;

  .img_video{
    filter: invert(1);
  }
  img{
    filter: invert(1);
  }
}
.eye-protection-mode img{/* 护眼模式 */
  /* filter: none; */
  /* filter: invert(1) hue-rotate(-100deg) brightness(100%) saturate(10%); */
  /* filter:  none !important; */
  
}
.img_video{
  /* filter: invert(1); */
}
.img_video_{
  filter: invert(1);
}
.logo{
  font-size: 50px;
  font-weight: bold;
  text-align: center;
}
.logo img{
  
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.one_line_hidden{
  white-space: nowrap; /* 确保文本在一行内显示 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
}
</style>
